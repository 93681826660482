<template>
	<a class="route-card" :title="title" @click="openPath(path)">
		<div class="route-image" :style="`background-image: url(${image})`"></div>
		<div class="container-media-chips">
			<MaoChipIconText v-if="ar" icon="view_in_ar" />
			<MaoChipIconText v-if="imagesMode" icon="imagesmode" />
			<MaoChipIconText v-if="headphones" icon="headphones" />
			<MaoChipIconText v-if="video" icon="smart_display" />
			<MaoChipIconText v-if="sign_language" icon="sign_language" />
			<MaoChipIconText
				v-for="(icon, index) in icons"
				:key="index"
				:icon="icon"
				:mode="iconMode" />
		</div>
		<div class="bottom-container">
			<h3 class="route-title title-big">{{ title }}</h3>
			<div class="container-info-chips">
				<MaoChipIconText v-if="duration_label" :icon="'history_toggle_off'" :aria-label="duration_label" :label="duration_label" />
				<MaoChipIconText
					:aria-label="`${artworksNumber} ${labels['Artworks']}`"
					v-if="artworksNumber"
					icon="sculpture"
					:label="`${artworksNumber} ${labels['Artworks']}`" />
			</div>
		</div>
	</a>
</template>

<script>
	import MaoChipIconText from "./MaoChipIconText.vue";
	import { useAppDataStore } from "@/stores/appData.js";

	export default {
		components: {
			MaoChipIconText
		},
		props: [
			'ar',
			'artworksNumber',
			'duration',
			'headphones',
			'image',
			'imagesMode',
			'sign_language',
			'title',
			'video',
			'path'
		],
		setup() {
			const appDataStore = useAppDataStore();
			return { appDataStore };
		},
		methods: {
			openPath(path) {
				this.$router.push({name: 'Path', params: {id: path.id, lang: this.$route.params.lang}});
			}	
		},
		computed: {
			labels() {
				return this.appDataStore.appData.labels;
			},
			duration_label() {
				switch (this.duration) {
					case "short":
						return "Short duration";
					case "medium":
						return "Medium duration";
					case "long":
						return "Long duration";
					default:
						return "";
				}
			},
			icons() {
				let icons = [];
				for(let type of this.path.types) {
					switch (type) {
						case "text":
							icons.push("contract");
							break;

						case "image":
							icons.push("imagesmode");
							break;

						case "image-gallery":
							icons.push("imagesmode");
							break;

						case "audio":
							icons.push("headphones");
							break;

						case "video":
							icons.push("smart_display");
							break;

						case "pattern-file":
							icons.push("view_in_ar");
							break;
					}
				}

				// remove duplicates
				icons = [...new Set(icons)];

				return icons;
			}
		},
	};
</script>

<style scoped lang="scss">
	.route-card {
		display: inline-flex;
		position: relative;
		min-height: 14rem;
		width: 100%;
		padding: var(--padding-l);
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		border-radius: var(--border-radius-m);
		background-color: var(--surface-background-variant);
		cursor: pointer;
		transition: border-radius 0.3s;

		.container-media-chips {
			display: flex;
			gap: var(--gap-xs);
			color: var(--text-on-surface-standard-opacity-dark);
		}
		.route-image {
			background-blend-mode: multiply, normal;
			inset: 0;
			border-radius: var(--border-radius-m);
			width: 100%;
			height: 100%;
			position: absolute;
			background-size: cover;
			background-position: center center;
			mix-blend-mode: multiply;
			opacity: 0.2;
			filter: grayscale(100%);
			transition: opacity 0.3s, border-radius 0.3s;
		}

		&:active,
		&:hover {
			.route-image {
				opacity: 0.1;
			}
		}

		&:active {
			border-radius: var(--border-radius-l);
			.route-image {
				border-radius: var(--border-radius-l);
			}
		}

		@include breakpoint(s) {
			border-radius: var(--border-radius-m) !important;
			.route-image {
				border-radius: var(--border-radius-m) !important;
			}
		}

		.bottom-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: var(--margin-xs);
			align-self: stretch;
			.route-title {
				display: flex;
				align-items: flex-start;
				gap: var(--gap-s);
				align-self: stretch;
				color: var(--text-on-surface-standard);
			}

			.container-info-chips {
				display: flex;
				gap: var(--gap-s);
				flex-wrap: wrap;
			}
		}
	}
</style>
