<template>
	<Teleport to="body" v-if="audio && audio.url">
	<MaoOverlay :status="status" @close="stopAndClose" />
	<transition name="fade">
		<div class="audio-player" :class="{ active: status }" v-if="status">
			<MaoButton
				class="close-button-audio-player"
				variant="text-button-opacity-light"
				icon="close"
				@close="stopAndClose"
			/>

			<div class="audio-header">
				<div class="title body-big-Semibold">{{ title }}</div>
			</div>
			<div class="audio-content">

				<MaoLoader v-if="!audioReady && !audioError" class="loading" />

				<div v-else>
				<div class="actions">
					<MaoButton
					class="replay-button"
					@click="replay10"
					icon="replay_10"
					variant="secondary"
					/>

					<MaoButton
						class="play-button"
						:disabled="!audioReady"
						@click="onPlayButtonClick"
						:icon="playing ? 'pause' : 'play_arrow'"
						variant="secondary"
					/>
					<MaoButton
						class="forward-button"
						@click="forward10"
						icon="forward_10"
						variant="secondary"
					/>
				</div>

				<div class="audio-bar-container">
					<div class="current-time label-big">{{ formatAudioLength(current_time) }}</div>
					<div class="audio-bar" @click="setProgress">
					<div class="progress-bar" :style="{ width: getProgressBarWidth() + '%' }">
						<div
							class="time-dot"
							v-show="hover_active || dragging"
							:style="{ left: hoverPosition + '%' }"
							@mousedown="startDrag"
							@touchstart.prevent="startDrag"
						></div>
					</div>
					</div>
					<div class="end-time label-big">{{ formatAudioLength(audio_length) }}</div>
				</div>
				</div>
			</div>
			<div class="audio-footer">
				<div
					class="transcription"
					:class="{ active: transcriptionActive }"
					v-if="audio && audio['audio-transcription']"
				>
				<div class="transcription-header" @click="toggleTranscription">
					<div class="label-big">{{ labels['Transcription'] }}</div>
					<MaoButton
						@toggle="toggleTranscription"
						:icon="transcriptionActive ? 'collapse_content' : 'expand_content'"
						variant="text-button-opacity-light"
					/>
				</div>
				<div class="transcription-content">
					<div class="transcription-html" v-html="audio['audio-transcription']"></div>
				</div>
			</div>
		</div>
	</div>
	</transition>
	</Teleport>
</template>

<script>
import { defineComponent } from "vue";
import { useAppDataStore } from "@/stores/appData";
import MaoButton from "./MaoButton.vue";
import MaoOverlay from "./MaoOverlay.vue";
import MaoLoader from "./MaoLoader.vue";

export default defineComponent({
	emits: ["close"],
	components: {
	MaoButton,
	MaoOverlay,
	MaoLoader
	},
	setup() {
		const appDataStore = useAppDataStore();
		return { labels: appDataStore.appData.labels };
	},
	props: {
		title: {
			type: String,
			default: "Audio Player",
		},
		audio: {
			type: Object,
			required: true,
		},
		status: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			current_time: 0,
			playing: false,
			audio_instance: null, // Istanza Audio
			audio_length: 0,
			hover_active: false,
			hoverPosition: 0,
			dragging: false,
			transcriptionActive: false,
			audioReady: false,
			audioError: false,
		};
	},
	watch: {
		playing(newVal) {
			if (newVal && this.audio_instance) {
			this.audio_instance
				.play()
				.then(() => {
				console.log("Audio in riproduzione");
				})
				.catch((error) => {
				console.error("Errore durante la riproduzione:", error);
				this.playing = false;
				});
			} else if (this.audio_instance) {
			this.audio_instance.pause();
			}
		},
		audio(newVal) {
			if (newVal && newVal.url) {
			this.loadAudio(newVal.url);
			}
		},
		status(newVal) {
			if (!newVal && this.playing) {
			this.stopAndClose();
			}
		},
	},
	mounted() {
		if (this.audio && this.audio.url) {
			this.loadAudio(this.audio.url);
		}

		document.addEventListener("mousemove", this.dragProgress);
		document.addEventListener("mouseup", this.stopDrag);
		document.addEventListener("touchmove", this.dragProgress, { passive: false });
		document.addEventListener("touchend", this.stopDrag);
	},
	methods: {
		loadAudio(url) {
			// Se esiste già un'istanza Audio, interrompi e rimuovi gli event listeners
			if (this.audio_instance) {
				this.audio_instance.pause();
				this.removeAudioEventListeners();
				this.audio_instance = null;
			}

			// Crea una nuova istanza Audio
			this.audio_instance = new Audio(url);
			this.audio_instance.preload = "metadata";

			// when audio_istance has loaded set audioReady to true

			// Aggiungi gli event listeners
			this.audio_instance.addEventListener("canplay", this.onCanPlay);
			this.audio_instance.addEventListener("loadedmetadata", this.audioLoaded);
			this.audio_instance.addEventListener("timeupdate", this.updateCurrentTime);
			this.audio_instance.addEventListener("ended", this.onAudioEnded);
			this.audio_instance.addEventListener("error", this.handleAudioError);
		},
		removeAudioEventListeners() {
			if (this.audio_instance) {
			this.audio_instance.removeEventListener("canplay", this.onCanPlay);
			this.audio_instance.removeEventListener("loadedmetadata", this.audioLoaded);
			this.audio_instance.removeEventListener("timeupdate", this.updateCurrentTime);
			this.audio_instance.removeEventListener("ended", this.onAudioEnded);
			this.audio_instance.removeEventListener("error", this.handleAudioError);
			}
		},
		onCanPlay() {
			this.audioReady = true;
			console.log("Audio pronto per la riproduzione.");
		},
		onAudioEnded() {
			this.playing = false;
			this.current_time = this.audio_length;
			console.log("Audio terminato.");
		},
		handleAudioError(e) {
			console.error("Errore nel caricamento dell'audio:", e);
			this.audioReady = false;
			this.audioError = true;
			this.playing = false;
			this.$emit("close");
		},
		stopAndClose() {
			console.log("Stop and close audio player");
			this.playing = false;
			this.$emit("close");
		},
		toggleTranscription() {
			this.transcriptionActive = !this.transcriptionActive;
		},
		formatAudioLength(length_in_seconds) {
			const minutes = Math.floor(length_in_seconds / 60);
			let seconds = length_in_seconds % 60;
			seconds = Math.floor(seconds);
			return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
		},
		onPlayButtonClick() {
			console.log("Play button clicked, current playing state:", this.playing);
			this.playing = !this.playing;
		},
		getProgressBarWidth() {
			const progress = (this.current_time / this.audio_length) * 100;
			return Math.min(Math.max(progress, 0), 100);
		},
		audioLoaded() {
			if (this.audio_instance) {
				this.audio_length = this.audio_instance.duration;
				console.log("Audio loaded, duration:", this.audio_length);
				this.audioReady = true;
			}
		},
		updateCurrentTime() {
			if (this.audio_instance) {
				this.current_time = this.audio_instance.currentTime;
			}
		},
		setProgress(event) {
			const progressBar = event.currentTarget;
			if (!progressBar) {
			return;
			}
			const isTouchEvent = event.type === "touchstart";
			const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
			const progressBarRect = progressBar.getBoundingClientRect();
			const clickX = clientX - progressBarRect.left; // Calcola la posizione del click all'interno dell'elemento
			const progressBarWidth = progressBarRect.width; // Usa width invece di clientWidth per maggiore precisione
			const clickTime = (clickX / progressBarWidth) * this.audio_length; // Calcola il tempo da seekare
			if (this.audio_instance) {
			this.audio_instance.currentTime = clickTime;
			console.log("Seek to:", clickTime);
			}
		},
		replay10() {
			if (this.audio_instance) {
			this.audio_instance.currentTime = Math.max(this.audio_instance.currentTime - 10, 0);
			console.log("Replay 10 secondi, nuovo tempo:", this.audio_instance.currentTime);
			}
		},
		forward10() {
			if (this.audio_instance) {
			this.audio_instance.currentTime = Math.min(
				this.audio_instance.currentTime + 10,
				this.audio_length
			);
			console.log("Forward 10 secondi, nuovo tempo:", this.audio_instance.currentTime);
			}
		},
		startDrag(event) {
			event.preventDefault();
			this.dragging = true;
			console.log("Inizio drag");
		},
		dragProgress(event) {
			if (!this.dragging) return;
			event.preventDefault();
			this.setProgress(event);
			console.log("Dragging...");
		},
		stopDrag() {
			if (this.dragging) {
			this.dragging = false;
			console.log("Stop drag");
			}
		},
	},
	beforeUnmount() {
		if (this.audio_instance) {
			this.audio_instance.pause();
			this.removeAudioEventListeners();
			this.audio_instance = null;
		}
		document.removeEventListener("mousemove", this.dragProgress);
		document.removeEventListener("mouseup", this.stopDrag);
		document.removeEventListener("touchmove", this.dragProgress);
		document.removeEventListener("touchend", this.stopDrag);
	},
});
</script>

<style lang="scss" scoped>
.audio-player {
	position: fixed;
	bottom: 0;
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	gap: var(--gap-l);
	background: var(--surface-contrast-container-high);
	padding-top: var(--padding-l);
	border-top-left-radius: var(--border-radius-m);
	border-top-right-radius: var(--border-radius-m);
	color: var(--text-on-surface-invert);
	transition: all 0.5s;
	z-index: 3;
	transform: translateY(200%);

	&.active {
	display: flex;
	animation: slide-up 0.5s forwards;
	}

	@keyframes slide-up {
	0% {
		transform: translateY(200%);
	}
	100% {
		transform: translateY(0);
	}
	}

	.close-button-audio-player {
	position: absolute;
	top: -3.5rem;
	right: 1rem;
	}

	.audio-bar-container {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	}

	.audio-header {
	width: 100%;
	.title {
		text-align: center;
	}
	}
	.audio-content {
	width: 100%;
	gap: var(--gap-l);
	display: flex;
	flex-direction: column;
	padding-inline: var(--padding-l);
	width: 100%;
		
		.loading {
			border-top-left-radius: var(--border-radius-m);
			border-top-right-radius: var(--border-radius-m);
		}

	.current-time,
	.end-time {
		line-height: 1.2;
	}

	.actions {
		display: flex;
		gap: var(--gap-xl);
		justify-content: center;
		align-items: center;
		.replay-button,
		.forward-button {
		background: var(--surface-opacity-high-light);
		border-radius: 100%;
		}
		.play-button {
		[class^="icon-"] {
			font-size: var(--icon-size-xl);
		}
		}
	}

	.audio-bar-container {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	.audio-bar {
		display: flex;
		height: 0.375rem;
		padding-right: var(--padding-s);
		flex-direction: column;
		align-items: flex-start;
		gap: 0.625rem;
		flex: 1 0 0;
		border-radius: var(--border-radius-s);
		background: var(--surface-opacity-high-light);
		position: relative;
		cursor: pointer;

		.progress-bar {
		display: flex;
		width: 0%;
		height: 100%;
		background: var(--surface-opacity-low-light);
		border-radius: var(--border-radius-s);
		position: relative;
		transition: 0.2s all;

		.time-dot {
			position: absolute;
			top: 0;
			left: 0;
			width: 1.4rem;
			height: 1.4rem;
			border-radius: 50%;
			background: var(--surface-contrast-container-lowest);
			cursor: pointer;
			transform: translateY(calc(-50% + 0.1875rem));
		}
		}
	}
	}
	.audio-footer {
	width: 100%;
	.transcription {
		border-top-left-radius: var(--border-radius-m);
		border-top-right-radius: var(--border-radius-m);
		background-color: var(--surface-opacity-high-light);
		padding-inline: var(--padding-l);
		transition: all 0.3s;
		&.active {
		background-color: var(--text-on-secondary-container);
		}
		.transcription-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-block: var(--padding-m);
		cursor: pointer;
		color: var(--text-on-surface-standard-opacity-light);
		}
		.transcription-content {
		height: 0;
		overflow: hidden;
		transition: all 0.5s;
		padding-block: 0;
		overflow-y: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		position: relative;
		&::-webkit-scrollbar {
			display: none;
		}
		.transcription-html {
			padding-bottom: 10vh;
		}
		/* add gradient on bottom */
		}
		&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		pointer-events: none;
		height: 20vh;
		background: linear-gradient(0deg, black, transparent);
		opacity: 0;
		transition: opacity 0.5s;
		}
		&.active {
		.transcription-content {
			padding-block: var(--padding-l);
			height: 30vh;
		}
		&:after {
			opacity: 1;
		}
		}
	}
	}
}

.audio-content {
	.actions {
	.play-button {
		[class^="icon-"] {
		font-size: var(--icon-size-xl);
		}
	}
	}
}

.loading {
	/* Stile per il messaggio di caricamento */
	color: var(--text-on-surface-standard-opacity-light);
	text-align: center;
	padding: var(--padding-l);
}

.error {
	/* Stile per il messaggio di errore */
	color: red;
	text-align: center;
	padding: var(--padding-l);
}
</style>
