<template>
	<Teleport to="body">
		<div class="info-modal-container grid-x">
			<Transition name="fade">
				<div class="info-modal" v-if="status">
					<MaoButton class="close-button" variant="opacity-dark" icon="close" @click="emitClose" />
					<div class="title">
						<h1 class="title-small">{{ appData.pages.about.title }}</h1>
						<h2 class="title-small">{{ appData.pages.about.subtitle }}</h2>
					</div>
					<div class="description">
						<p class="text" v-html="appData.pages.about.description"></p>
					</div>
					<MaoSeparator />
					<h4 class="body-big-Semibold">{{ appData.labels['Settings and Info'] }}</h4>
					<div>
						{{ appData.labels['Contact us'] }} <a href="mailto:maodidattica@findazionetorinomusei.it">maodidattica@findazionetorinomusei.it</a>
					</div>					
					<MaoButton variant="opacity-dark" :label="appData.labels['Language']+': '+ appData.langLabel" @click="langs = !langs" />
					<div class="languages" :class="{ active: langs }">
						<MaoButton  v-for="lang in appData.languages" :key="lang" variant="text-button-opacity-dark" :label="lang.native_name" @click="() => changeLang(lang.code)" />
					</div>
					<MaoButton variant="opacity-dark" :label="appData.labels['Contacts']" @open="gotoContacts" />
					<MaoButton variant="primary" label="Tutorial MaoApp" @open="gotoTutorial" />
					<img class="logo" alt="Logo MAO" :src="logoPath" @click="$router.push('/')">
				</div>
			</transition>
		</div>
	</Teleport>
</template>

<script>
import { useAppDataStore } from "./../stores/appData";
import { useGlobalActionsStore } from "./../stores/globalActions";
import MaoButton from "./MaoButton.vue";
import MaoSeparator from "./MaoSeparator.vue";

	export default {
		emits: ["close"],
		components: {
			MaoButton,
			MaoSeparator,
		},
		data() {
			return {
				logoPath: require("@/assets/logo.png"),
				langs: false
			}
		},
		setup() {
			const { appData, setLang, loadLang  } = useAppDataStore();
			const globalActionsStore = useGlobalActionsStore();
			return { appData, setLang, loadLang, globalActionsStore};
		},
		props: {
			status: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			gotoContacts() {
				this.$router.push({name: 'Contacts', params: {lang: this.$route.params.lang }});
			},
			changeLang(lang) {
				this.$router.push({name: 'Home', params: {lang: lang }});
				
				setTimeout(() => {
					window.location.reload();
				}, 50);

				this.$emit("close");
				
			},
			gotoTutorial() {
				this.$emit("close");
				this.$router.push({name: 'Tutorial', params: {lang: this.$route.params.lang }});
			},
			emitClose() {
				this.globalActionsStore.showHeader();
				this.$emit("close");
			}
		},
	};
</script>

<style scoped lang="scss">
	.info-modal-container {
		position: fixed;
		top: 50%;
		min-height: 100%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 12;
		pointer-events: none;
		width: 100%;
		left: 50%;

		.languages {
			display: none;
			transition: all 0.3s;
			gap: var(--gap-s);
			flex-direction: column;
			
			&.active {
				display: flex;
			}

		}

		.logo {
			position: fixed;
			top: var(--padding-m);
			left: var(--padding-m);
			width: 2.1rem;
			cursor: pointer;
		}

		.button-close {
			position: absolute;
			top: auto;
			right: var(--padding-m);
		}
		
		.info-modal {
			display: flex;
			padding: var(--padding-l);
			padding-top: 5rem;
			flex-direction: column;
			gap: var(--gap-m);
			border-radius: var(--border-radius-m);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			transition: all 0.3s;
			grid-column: window-start / window-end;
			background-color: var(--surface-contrast-container-low);
			pointer-events: none;

			height: 100vh;
			overflow: scroll;
			padding-bottom: 8rem;
			display: flex;
			opacity: 1;
			pointer-events: all;

			.info-modal {
				animation: zoomInFadeIn 0.5s ease-out forwards;
				pointer-events: all;
				opacity: 1;
			}
			
			h2,h3 {
				color: var(--text-on-primary-variant);
			}

			a {
				color: var(--text-on-primary-variant);
			}
			h4 {
				color: var(--text-onSurface-standard-opacity-dark);
			}

			.button {
				justify-content: center;
			}

			.text {
				color: var(--text-on-surface-variant-high);
			}

			.buttons {
				display: flex;
				gap: var(--gap-s);
				align-self: stretch;
			}
		}
	}
	.close-button {
		z-index: 10;
		position: absolute;
		top: 0.5rem;
		right: 1rem;
	}
</style>