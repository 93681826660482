<template>
	<Teleport to="body">
		<div v-if="status">
			<transition name="fade">
				<div>
					<MaoOverlay :status="status" @close="stopAndClose" />
					<div class="grid-x video-container" :class="{ active: status }">
						<MaoButton
							class="close-button-video-player"
							variant="text-button-opacity-light"
							icon="close"
							@close="stopAndClose" />

						<iframe
							:title="title"
							:style="{ aspectRatio: video['aspect-ratio'] }"
							:src="vimeoUrl"
							frameborder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							allowfullscreen
							ref="vimeoPlayer"></iframe>
					</div>
					<div class="grid-x title-container" :class="{ active: status }">
						<div class="title body-small">{{ title }}</div>
					</div>
				</div>
			</transition>
		</div>
	</Teleport>
</template>

<script>
	import MaoOverlay from "./MaoOverlay.vue";
	import MaoButton from "./MaoButton.vue";
	import { useGlobalActionsStore } from "@/stores/globalActions";
	import { useBottomMenuStore } from "@/stores/bottomMenu";

	export default {
		emits: ["close"],
		components: {
			MaoOverlay,
			MaoButton
		},
		props: {
			title: {
				type: String,
				required: true
			},
			video: {
				type: Object,
				required: true
			},
			status: {
				type: Boolean,
				required: true
			}
		},
		setup() {
			const globalActionsStore = useGlobalActionsStore();
			const bottomMenuStore = useBottomMenuStore();
			return { globalActionsStore, bottomMenuStore };
		},
		watch: {
			status(newVal) {
				if (newVal) {
					this.globalActionsStore.hideHeader();
					this.bottomMenuStore.hideBottomMenu();
				} else {
					this.globalActionsStore.showHeader();
					this.bottomMenuStore.showBottomMenu();
				}
			}
		},
		computed: {
			vimeoUrl() {
				// Estrai l'ID del video e il token privato (se presente) dall'URL
				const regex = /vimeo\.com\/(\d+)(?:\/(\w+))?/;
				const match = this.video.url.match(regex);

				if (match) {
					const videoId = match[1];
					const privateToken = match[2] ? `/${match[2]}` : "";
					return `https://player.vimeo.com/video/${videoId}${privateToken}`;
				} else {
					// Se l'URL non è formattato correttamente, gestiscilo come errore
					console.error("URL Vimeo non valido:", this.video.url);
					return "";
				}
			}
		},
		methods: {
			stopAndClose() {
				this.playing = false;
				this.$emit("close");
				// stop vimeo
				const vimeoPlayer = this.$refs.vimeoPlayer;
				vimeoPlayer.contentWindow.postMessage('{"method":"pause"}', "*");
			}
		}
	};
</script>

<style scoped lang="scss">
	.video-container {
		position: fixed;
		width: 100%;
		display: inline-grid;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		opacity: 0;
		transition: all 0.3s;
		pointer-events: none;

		iframe {
			grid-column: main-column;
			position: relative;
			inset: 0;
			width: 100%;
			height: 100%;
		}
		.close-button-video-player {
			position: absolute;
			top: -3.5rem;
			right: 1rem;
		}
	}

	.title-container {
		position: fixed;
		bottom: 1rem;
		display: grid;
		z-index: 2;
		opacity: 0;
		transition: all 0.3s;
		pointer-events: none;
		width: 100%;
		.title {
			color: white;
			grid-column: main-column;
		}
	}
	.active {
		opacity: 1;
		pointer-events: all;
	}
</style>
