import { defineStore } from "pinia";

export const useBottomMenuStore = defineStore("useBottomMenuStore", {
	state: () => ({
		bottomMenuStatus: false,
		bottomMenuComponent: false,
		stepper: {
			current: 1,
			start: 1,
			end: 2,
			label: "",
			increment: false,
			decrement: false
		},
		button: {
			label: "",
			action: false
		}
	}),

	actions: {
		hideBottomMenu() {
			this.bottomMenuStatus = false;
		},
		showBottomMenu() {
			this.bottomMenuStatus = true;
		},
		setBottomMenu(status) {
			this.bottomMenuComponent = "navigationMenu";
			this.bottomMenuStatus = status;
		},
		mountStepper(data) {
			this.bottomMenuComponent = "stepper";
			this.stepper = data;
		},
		mountButton(data) {
			this.bottomMenuComponent = "button";
			this.button = data;
			this.bottomMenuStatus = true;
		},
		clearButton() {
			this.button = {
				label: "",
				action: false
			};
			this.bottomMenuComponent = "navigationMenu";
		},
		clearStepper() {
			this.bottomMenuComponent = "navigationMenu";
			this.stepper = {
				current: 1,
				start: 1,
				end: 2,
				label: "",
				increment: false,
				decrement: false
			};
		}
	}
});
