<template>
    <a href="#main-content" tabindex="0" class="skip-link">Skip to content</a>
	<div class="header-container grid-x" :class="{loaded: loaded, hide: hide}" v-if="loaded">
		<div class="header fullscreen grid-x" :class="{[background]: true}">
			<div class="main-column">
				<div class="left-container">
					<a href="/" class="logo" @click="$router.push('/')" aria-label="Go to home" title="Go to home">
						<img v-if="logo" class="logo" alt="Logo MAO" :src="logoPath" @click="$router.push('/')">
					</a>
					<div v-if="backTo"><MaoButton @click="backTo.action" icon="arrow_back_ios" :label="title? '' : backTo.label" :variant="background == 'dark'? 'text-button-opacity-light' : 'text-button'"/></div>
				</div>
				<div class="center-container">
					<div v-if="title" class="page-title body-small">{{ title }}</div>
				</div>
				<div class="right-container">
					<MaoButton variant="text-button" icon="info" @open="openInfo" />
					<div aria-hidden="true">
						<MaoButton icon="qr_code_scanner" @open="openQRScanner" aria-hidden="true" ariaHidden="true" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<MaoQRScanner  :status="qrScannerStatus" @update:status="qrScannerStatus = $event" />
	<MaoInfo v-if="loaded" :status="info" @close="info = false" />
</template>

<script>
import { useAppDataStore } from "@/stores/appData";
import { useGlobalActionsStore } from "@/stores/globalActions";
import MaoButton from "./MaoButton.vue";
import MaoInfo from "./MaoInfo.vue";
import MaoQRScanner from "./MaoQRScanner.vue";

export default {
	components: {
		MaoButton,
		MaoInfo,
		MaoQRScanner
	},
	data() {
		return {
			title: "",
			background: "",
			backTo: false,
			logo: true,
			logoPath: require("@/assets/logo.png"),
			info: false,
			qrScannerStatus: false,
		}
	},
	setup() {
		const appDataStore = useAppDataStore();
		const globalActionsStore = useGlobalActionsStore();

		return { appDataStore, globalActionsStore };
	},
	computed: {
		labels() {
			console.log(this.appDataStore.appData.labels);
			return this.appDataStore.appData.labels;
		},
		loaded() {
			return this.appDataStore.appData.loaded;
		},
		hide() {
			return !this.globalActionsStore.header;
		},
		exception() {
			return this.globalActionsStore.headerException;
		},
		prevPath() {
			return this.globalActionsStore.prevPath;
		}
	},
	watch: {
		'$route' () {
			this.styleHeader();
		},
		exception() {
			this.styleHeader();
		},
		prevPath() {
			this.styleHeader();
		}
	},
	methods: {
		styleHeader() {
			let switchCase = this.$route.name;
			if(this.exception) {
				switchCase = 'preview-artwork';
			}

			this.globalActionsStore.showHeader();

			switch(switchCase) {
				
				case 'about':
					this.background = 'beige';
					this.title = this.labels['About'];
					this.logo = false;
					this.backTo = false;
				break;

				case 'Artwork':
					this.background = 'dark';
					this.backTo = {
						label: this.labels['Artworks'],
						action: () => {
							this.$router.push({ name: 'Artworks', lang: this.$route.params.lang });
						}
					};
					this.title = '';
					this.logo = false;
				break;
				
				case 'Gallery':
					this.background = 'beige';
					this.backTo = {
						label: this.labels['Back to Explore'],
						action: () => {
							this.$router.push({ name: 'Explore', lang: this.$route.params.lang});
						}
					};
					this.title = '';
					this.logo = false;
				break;

				case 'Artworks':
					this.background = 'transparent';
					this.title = this.labels['Artworks'];
					this.backTo = false;
					this.logo = true;
					this.title = false;
				break;

				case 'singlePath':
					this.background = 'dark';
					this.title = this.labels['Back to itineraries'];
					this.backTo = {
						label: this.labels['Back to itineraries'],
						action: () => {
							this.$router.push({ name: 'Paths', lang: this.$route.params.lang});
						}
					};
					this.logo = false;
				break;

				case 'Paths':
					this.background = 'transparent';
					this.title = '';
					this.backTo = false;
					this.logo = true;
				break;

				case 'Path':
					this.background = 'beige';
					this.title = '';
					this.backTo = {
						label: this.labels['Back to itineraries'],
						action: () => {
							this.$router.push({ name: 'Paths', lang: this.$route.params.lang });
						}
					};
					this.logo = false;
				break;

				case 'home':
					this.background = 'transparent';
					this.title = '';
					this.backTo = false;
					this.logo = true;
				break;

				case 'preview-artwork':
					this.background = 'dark';
					this.title = this.exception;
					
					this.backTo = {
						label: this.labels['Back to Path'],
						action: () => {
							this.$router.push({ name: 'Path', lang: this.$route.params.lang, id: this.$route.params.id });
						}
					};

					this.logo = false;
				break;

				case 'PathArtwork':
					this.background = 'dark';
					this.title = '';
					
					this.backTo = {
						label: this.prevPath.label,
						action: this.prevPath.action
					};
					this.logo = false;
				break;

			}
		},
		openInfo() {
			this.globalActionsStore.hideHeader();
			this.info = true;
		},
		openQRScanner() {
			this.qrScannerStatus = true;
		},
	},
	mounted() {
		this.styleHeader();
		this.$router.afterEach(() => {
			this.styleHeader();
		});
		window.addEventListener('scroll', this.onScroll);
	}
};
</script>

<style scoped lang="scss">
	.header-container {
		position: fixed;
		top: -1px;
		left: 50%;
		width: 100%;
		z-index: 101;
		transform: translateY(-100%) translateX(-50%);
		transition: all 0.6s;

		&.loaded {
			transform: translateY(0) translateX(-50%);
		}

		&.hide {
			transform: translateY(-100%) translateX(-50%);
			/* background-color: transparent !important; */
		}
		
		.header {
			width: 100%;
			padding: var(--padding-m) 0;
			border-radius: 0rem 0rem var(--border-radius-s) var(--border-radius-s);
			backdrop-filter: blur(0.125rem);
			background: var(--surface-background-standard);
			transition: all 0.3s;
			color: var(--text-on-surface-standard);

			.main-column {
				display: flex;
				justify-content: space-between;
				align-items: center;
				justify-content: space-between;
				align-items: center;
				.left-container {
					cursor: pointer;
				}
	
				.logo {
					transition: 0.3s opacity;
					width: 2.1rem;
					height: 2.1rem;
				}
				.page-title {
					color: currentColor;
					/* margin-right: calc(calc( calc(var(--padding-s) * 2) + var(--icon-size-m)) * -1); */
				}
			}

			&.beige {
				background: var(--surface-background-variant);
			}
			&.dark {
				color: var(--text-on-surface-invert);
				background: var(--surface-background-variant-dark);

				.has-text {
					color: var(--text-on-surface-invert);
				}

				.button-info,
				.button-qr_code_scanner {
					opacity: 0;
					display: none; 
				}
			}



			.center-container {
				* {
					text-align: center;
				}
			}

			.right-container {
				display: flex;
				gap: 0.5rem;
			}
		}
	}

	.skip-link {
		position: absolute;
		top: -42px;
		left: 0;
		background: #000;
		color: #fff;
		padding: 8px;
		z-index: 100;
		transition: top 0.3s;
		&:focus {
			top: 0;
		}
		@include breakpoint(s) {
			display: none;
		}
	}

</style>
